<template>
  <div>
    <FilterOverview title="Kontrollansicht Daten" :showFilterInitially="false" @reload="onReload">
      <template #title-right>
        <b-button
          class="btn-xs btn p-2 ml-2 justify-content-center align-item-center d-flex"
          v-b-tooltip.hover
          :title="controllViewTitleHoverText"
        >
          <i class="fas fa-info m-0 p-0"></i>
        </b-button>
      </template>
      <template #table="{ tableHeight }">
        <AgGridVue
          :style="{ height: tableHeight + 80 + 'px' }"
          class="ag-theme-alpine m-0 p-0"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :statusBar="statusBar"
          :tooltipShowDelay="0"
          :defaultColDef="defaultColDef"
          :rowModelType="'clientSide'"
          :rowData="rowData"
          :suppressCellFocus="true"
        ></AgGridVue>
      </template>
    </FilterOverview>
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import FilterOverview from '@/components/common/filter-overview.vue';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import apiService from '@/core/common/services/api.service';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import { format, parse } from 'date-fns';

export default {
  name: 'KontrollansichtDatenComponent',
  components: {
    AgGridVue,
    FilterOverview,
    StatusBarComponent,
  },
  data() {
    return {
      columnDefs: null,
      controllViewTitleHoverText:
        'Der linke Wert einer Spalte stammt aus dem Manager. Der rechte Wert stammt aus Blank.',
      defaultColDef: {
        floatingFilter: false,
        filter: false,
        flex: 1,
        resizable: false,
        sortable: true,
        suppressMenu: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        filterParams: {
          defaultToNothingSelected: true,
        },
      },
      gridApi: null,
      gridColumnApi: null,
      rowData: null,
      statusBar: null,
      count: null,
    };
  },
  created() {
    this.columnDefs = [
      {
        headerName: 'Reisekürzel',
        field: 'reiseterminkuerzel',
        valueFormatter: function (params) {
          return params.value.substring(0, 6);
        },
        pinned: 'left',
        minWidth: 120,
        width: 120,
      },
      {
        headerName: 'Startdatum',
        field: 'reiseterminkuerzel',
        valueFormatter: function (params) {
          const dateUnformatted = params.value.substring(7, 17);
          // format with date-fns to dd.MM.yyyy
          return format(parse(dateUnformatted, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy');
        },
        pinned: 'left',
        minWidth: 120,
        width: 120,
        sortable: false,
      },
      {
        headerName: 'Unterschiede',
        children: [
          {
            field: 'thLangstrecke',
            headerName: 'TH Langstrecke',
            cellRenderer: params => this.renderDifference(params, 'thLangstreckeCore', 'thLangstreckeBlank'),
            cellStyle: { display: 'flex', 'align-items': 'center', 'justify-items': 'center' },
            sortable: false,
          },
          {
            field: 'thInlandsflug',
            headerName: 'TH Inlandsflug',
            cellRenderer: params => this.renderDifference(params, 'thInlandsflugCore', 'thInlandsflugBlank'),
            cellStyle: { display: 'flex', 'align-items': 'center', 'justify-items': 'center' },
            sortable: false,
          },
          {
            field: 'minPax',
            headerName: 'Min Pax',
            cellRenderer: params => this.renderDifference(params, 'minPaxCore', 'minPaxBlank'),
            cellStyle: { display: 'flex', 'align-items': 'center', 'justify-items': 'center' },
            sortable: false,
          },
          {
            field: 'maxPax',
            headerName: 'Max Pax',
            cellRenderer: params => this.renderDifference(params, 'maxPaxCore', 'maxPaxBlank'),
            cellStyle: { display: 'flex', 'align-items': 'center', 'justify-items': 'center' },
            sortable: false,
          },
          {
            field: 'releasefrist',
            headerName: 'Releasefrist',
            cellRenderer: params => this.renderDifference(params, 'releasefristCore', 'releasefristBlank'),
            cellStyle: { display: 'flex', 'align-items': 'center', 'justify-items': 'center' },
            sortable: false,
          },
        ],
      },
      {
        field: 'actions',
        headerName: 'Aktionen',
        sortable: false,
        pinned: 'right',
        cellRenderer: function (params) {
          const linkReiseterminplanung = `/#/reisetermine/planung?reisetermine=${params.data.reiseterminkuerzel}&collapse=true`;
          const linkReisestammdaten = `/#/reisen/${params.data.reiseterminkuerzel.substring(
            0,
            6
          )}/reisestammdaten?reiseterminAbreisedatum=${params.data.reiseterminkuerzel.substring(7, 17)}`;
          return `
              <div class="d-flex" style="gap: 8px">
                <a href="${linkReiseterminplanung}" target="_blank" class="">
                  <span class="badge badge-secondary action-button">Planung</span>
                </a>
                <a href="${linkReisestammdaten}" target="_blank" class="">
                  <span class="badge badge-secondary action-button">Stammdaten</span>
                </a>
                </div>`;
        },
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
          'justify-items': 'center',
        },
      },
    ];
    this.statusBar = {
      statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      apiService.get('reisetermin/unterschiedeBlankCore').then(response => {
        const result = response.data.result;
        this.rowData = result.rows;
        this.count = result.totalCount;
        this.updateStatusBar(this.count);
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    updateStatusBar(displayedRowsCount) {
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setRowCount(displayedRowsCount);
      statusBarComponent?.setOdataCount(this.count);
    },
    onReload() {
      this.gridApi.refreshCells();
    },
    renderDifference(params, coreField, blankField) {
      const coreValue = params.data[coreField];
      const blankValue = params.data[blankField];

      const spanStyle =
        'height: 28px; display: inline-flex; align-items: center; justify-content: center; box-sizing: border-box;';

      const displayCoreValue = coreValue != null ? coreValue : '-';
      const displayBlankValue = blankValue != null ? blankValue : '-';

      if (displayCoreValue === displayBlankValue) {
        return `<span style="${spanStyle}" class='badge badge-secondary'>${displayCoreValue}</span>`;
      } else {
        return `
          <span style="${spanStyle}" class='badge badge-warning'>${displayCoreValue}</span>
          <span style="${spanStyle}" class='badge badge-warning'>${displayBlankValue}</span>`;
      }
    },
  },
};
</script>

<style scoped></style>
